<template>
  <!-- eslint-disable vue/no-v-html -->
  <signup-fail-template data-test="layout">
    <template #page_title>
      <div data-test="title" v-html="$t(`${langPath}.title`)" />
    </template>
    <template #content>
      <message
        data-test="warning_message"
        type="warning"
        :text="warningText"
        icon-name="exclamation-triangle"
      />

      <p data-test="description_line1" v-html="$t(`${langPath}.line1`)" />
      <p data-test="description_line2">{{ $t(`${langPath}.line2`) }}</p>
      <ev-link
        :a-href="signOutUrl"
        color="red"
        variant="primary"
        data-test="sign_out_button"
      >
        {{ $t(`${langPath}.accessButton`) }}
      </ev-link>
    </template>
  </signup-fail-template>
</template>

<script>
import SignupFailTemplate from './components/SignupFailTemplate'
import EvLink from '@revelotech/everestV2/EvLink'
import Message from './Message'
import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER } from '@/store/namespaces'
import { companiesUrl } from '@/helpers/constants/external-urls'

const authHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'InvalidEmail',
  components: {
    SignupFailTemplate,
    EvLink,
    Message
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...authHelper.mapState(['currentUser']),
    warningText () {
      return this.$t(`${this.langPath}.warning`)
    },
    signOutUrl () {
      return `${companiesUrl}/users/sign_out`
    }
  }
}
</script>

<style lang="scss">
.signup-fail-template {
  &__main {
    &-content {
      &-box {
        button {
          width: 100%;
        }
      }
    }
  }
}
</style>
